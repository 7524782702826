import { getLink, Link } from './getLink'

export interface FooterLinks {
    aboutUs: Link[]
    bottom: Link[]
    contactAndHelp: Link[]
    assuranceProducts: Link[]
    healthProducts: Link[]
    financialServices: Link[]
    getInTouch?: Link[]
    followUs?: Link[]
    workingWithIrish?: Link[]
}

export const getFooterLinks = (footerLinks: any): FooterLinks => {
    return {
        aboutUs: footerLinks.about_us.map((link: Link) => getLink(link)),
        bottom: footerLinks.bottom.map((link: Link) => getLink(link)),
        contactAndHelp: footerLinks.contact_and_help.map((link: Link) =>
            getLink(link)
        ),
        assuranceProducts: footerLinks.irish_life_assurance_products.map(
            (link: Link) => getLink(link)
        ),
        healthProducts: footerLinks.irish_life_health_products.map(
            (link: Link) => getLink(link)
        ),
        financialServices: footerLinks.irish_life_financial_services.map(
            (link: Link) => getLink(link)
        )
    }
}
