import { RouteComponentProps } from '@reach/router'
import { BlogPost, BlogPostProps } from 'common/components/templates/BlogPost'
import { getContactData } from 'common/utils/getContactData'
import { getFooterLinks } from 'common/utils/getFooterLinks'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface BlogPostPageProps extends RouteComponentProps {
    pageContext: any
}

export const BlogPostPage: React.FC<BlogPostPageProps> = ({ pageContext }) => {
    return <BlogPost {...getBlogData(pageContext)} />
}

export default BlogPostPage

export const getBlogData = (data: any): BlogPostProps => ({
    layout: {
        ...getLayoutData(data),
        jumbotronVariant: 'campaign',
        footer: {
            links: getFooterLinks(data.footer_links[0]),
            regLine: data.footer__regulatory_text[0].text,
            variant: 'form'
        }
    },
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },
    articleBody: { text: data.body },
    articleHeading: {
        authors: (data.authors || []).map((author: any) => author.text),
        date: data.date,
        image: data.image,
        tag: data.blog_post_category,
        title: data.title,
        entity: data.entities__entity
    },
    articleRelevant: {
        links: (data.related_articles || []).map((related: any) => ({
            label: related.title,
            url: `/blog/${related.url}`
        }))
    },
    contact: getContactData(data.contact_form[0])
})
