import { CallToActionProps } from 'common/components/molecules/CallToAction'
import { ContactProps } from 'common/components/organisms/Contact'
import { ContactConfirmationProps } from 'common/components/organisms/Contact/ContactLeft/ContactConfirmation'
import { ContactFeatureListProps } from 'common/components/organisms/Contact/ContactRight/ContactRightDetails'
import { JumbotronCallToActionProps } from 'common/components/organisms/Jumbotron/JumbotronCallToAction'

export const getContactData = (contactFormData: any) => {
    return {
        callToAction: {
            analyticsId: contactFormData.call_to_action__analytics_id,
            label: contactFormData.call_to_action__label,
            [`${contactFormData.call_to_action__iconlocation}Icon`]:
                contactFormData.call_to_action__icon,
            text: contactFormData.call_to_action__text,
            title: contactFormData.call_to_action__title,
            url: contactFormData.call_to_action__url,
            variant: contactFormData.call_to_action__variant,
        } as JumbotronCallToActionProps,
        advisorBooking: {
            title: contactFormData.custom_advisor_title,
            body: contactFormData.custom_advisor_body,
            bulletpoints: contactFormData.custom_advisor_bullet_points,
            advisor_image: contactFormData.custom_advisor_image,
            advisor_jobtitle:
                contactFormData.custom_advisor_information_job_title,
            advisor_name: contactFormData.custom_advisor_information_name,
            advisor_quotation: contactFormData.custom_advisor_quotation,
            custom_campaign_id: contactFormData.custom_campaign_id,
            custom_source_id: contactFormData.custom_source_id,
            custom_subsource_id: contactFormData.custom_subsource_id,
        },
        tabName: contactFormData.tab_name,
        title: contactFormData.contact_form_left_side__title,
        description: contactFormData.contact_form_left_side__description,
        formValues: {
            heading: contactFormData.contact_form_right_side__heading,
            titleForm: contactFormData.contact_form_right_side__title,
            descriptionForm:
                contactFormData.contact_form_right_side__description,
            firstLabelForm:
                contactFormData.contact_form_right_side__first_label,
            secondLabelForm:
                contactFormData.contact_form_right_side__second_label,
            footerDescriptionForm:
                contactFormData.contact_form_right_side__footer_description,
            hideForm:
                contactFormData.contact_form_right_side__hide_form === 'yes'
                    ? true
                    : false,
            noteDescriptionForm:
                contactFormData.contact_form_right_side__note_description,
            pickTimeSlotLabelForm:
                contactFormData.contact_form_right_side__pick_a_time_slot_label,
        } as ContactProps['formValues'],
        confirmation: {
            title: contactFormData.title,
            subTitle: contactFormData.subtitle,
            description: contactFormData.description,
            noteMessage: contactFormData.note_message,
        } as ContactConfirmationProps,
        featureList:
            contactFormData.contact_form_left_side__feature_list &&
            ((
                contactFormData.contact_form_left_side__feature_list as Array<any>
            ).map((feature_list: any) => ({
                title: feature_list.title,
                description: feature_list.description,
                icon: feature_list.icon,
                callToAction: {
                    label: '',
                    [`${feature_list.call_to_action_icon_location}Icon`]:
                        feature_list.call_to_action_icon,
                    text: feature_list.call_to_action_text,
                    url: feature_list.call_to_action_url,
                } as CallToActionProps,
            })) as ContactFeatureListProps[]),
    }
}
