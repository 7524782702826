import { Grid, Hidden } from '@material-ui/core'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import { Contact, ContactProps } from 'common/components/organisms/Contact'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ArticleBody,
    ArticleBodyProps,
    ArticleHeading,
    ArticleHeadingProps,
    ArticleRelevant,
    ArticleRelevantProps,
    Wrapper,
} from 'common/components/sharedLibrary'
import React from 'react'
import { Qualtrics } from '../atoms/Qualtrics'
import { useReadingTime } from 'react-hook-reading-time'

export interface BlogPostProps {
    alerts: AlertsProps
    articleBody: ArticleBodyProps
    articleHeading: ArticleHeadingProps
    articleRelevant: ArticleRelevantProps
    layout: LayoutProps
    contact: ContactProps
}

export const BlogPost: React.FC<BlogPostProps> = ({
    alerts,
    articleBody,
    articleHeading,
    articleRelevant,
    layout,
    contact,
}) => {
    const { minutes } = useReadingTime(articleBody.text)
    return (
        <Layout {...layout} box={{ overflow: 'visible' }}>
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <ArticleHeading
                            {...articleHeading}
                            readTime={minutes}
                        />
                        <ArticleBody text={articleBody.text} />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={1} />
                    </Hidden>
                    <Grid item xs={12} md={3}>
                        {articleRelevant.links.length ? (
                            <ArticleRelevant {...articleRelevant} />
                        ) : null}
                    </Grid>
                </Grid>
            </Wrapper>
            {alerts.alerts.length ? <Alerts {...alerts} /> : null}
            <Wrapper>
                <Qualtrics />
            </Wrapper>
            <Contact {...contact} />
        </Layout>
    )
}
